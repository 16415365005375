
import front from './components/media/front-end.svg';
import back from './components/media/back-end.svg';
import design from './components/media/web-design.svg';


export const portfolioData = [
    {
        id: 0,
        title:"Pizpie",
        vidUrl: "https://imgur.com/EvMc1SR.gif",
        websiteUrl: "https://www.pizpie.com/",
        githubUrl: "https://github.com/ms-neo/pizpie",
        tools:"React.js - Node.js - Mongodb"
    },
    {
    id: 1,
    title:"Jeff Portfolio",
    vidUrl: "https://imgur.com/oeQvmij.gif",
    websiteUrl: "https://resilient-naiad-10845a.netlify.app",
    githubUrl: "https://github.com/ms-neo/Jeffrey-portfolio",
    tools:"React.js - MatrialUi - Styled-Component - React-Spring "
},
{
    id: 2,
    title:"Guess The Game",
    vidUrl: "https://imgur.com/SyAjfvO.gif",
    websiteUrl: "https://whats-the-game.netlify.app/",
    githubUrl: "https://github.com/ms-neo/Guess-The-Game",
    tools:"Html - Css - Javascript"
}, 
{
    id: 3,
    title:"Endangered Animals",
    vidUrl: "https://imgur.com/6Cn8MWB.gif",
    websiteUrl: "https://endangered-animals.onrender.com",
    githubUrl: "https://github.com/ms-neo/endangered-animals",
    tools:"React.js - Node.js - MongoDB "
},
{
    id: 4,
    title:"Weather App",
    vidUrl: "https://imgur.com/ZptR5BL.gif",
    websiteUrl: "https://weather-app-6tub.onrender.com",
    githubUrl: "https://github.com/ms-neo/weather-app/tree/master",
    tools:"Html - Sass - Javascript - RestApi - Bootstrap - Node.js"

},
{
    id: 5,
    title:"Nobel Hands",
    vidUrl: "https://imgur.com/LArcybK.gif",
    websiteUrl: "https://nobel-hands-ms-neo.vercel.app/",
    githubUrl: "https://github.com/ms-neo/nobel-hands",
    tools:"Html - Css - Javascript"
}
];





export const skillsData = [{
        id: 0,
        title: 'Front-end development',
        skills: ['html', 'css', 'javascript', 'react', 'sass', 'bootstrap'],
        img: front
    },
    {
        id: 1,
        title: 'Back-end development',
        skills: ['node.js', 'MongoDB', 'REST-Api'],
        img: back
    },
    {
        id: 2,
        title: 'Web design',
        skills: 'occasinally design simple websites',
        img: design
    },
]
